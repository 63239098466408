import React, { useEffect, useState } from 'react';
import './Discounts.scss';

import { Checkbox, Table } from 'rsuite';
import Icon from '../../Icon';
import {
  CheckCell,
  EditableCell,
  EditableOrderCell,
  EditablePercentageCell,
  EditableVisibleCell,
} from '../../utils/cells';

import { useDispatch, useSelector } from 'react-redux';
import {
  createDiscounts,
  deleteDisconts,
  editDiscounts,
  getDiscounts,
} from '../../store/reducers/PracticeInfoReducer';
import Loader from '../Loader/Loader';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import toast, { Toaster } from 'react-hot-toast';

const { Column, HeaderCell } = Table;

const Discounts = () => {
  const dispatch = useDispatch();
  const { loading, treatmentTypes, discounts, error } = useSelector(
    (state) => state.discounts
  );
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [discountsArray, setDiscountsArray] = useState(discounts || []);

  const handleCheck = (value, checked) => {
    const nextCheckedKeys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((key) => key !== value);
    setCheckedKeys(nextCheckedKeys);
  };

  const handleCheckAll = (checked) => {
    const allKeys = checked ? discountsArray.map((item) => item.id) : [];
    setCheckedKeys(allKeys);
  };

  const onEditClick = () => {
    setIsEdit(true);
    const nextData = discountsArray.map((item) => ({
      ...item,
      status: isEdit ? null : 'EDIT',
    }));
    setDiscountsArray(nextData);
  };

  const onSaveClick = () => {
    setIsEdit(false);
    const sortedData = [...discountsArray]
      .reverse()
      .map((item, index) => ({ ...item, order: index + 1 }))
      .sort((a, b) => b.order - a.order);

    const createdData = sortedData.filter((item) => item.created);
    const editedData = sortedData.filter((item) => !item?.created);

    const nextCreateData = createdData.map((item) => {
      const { status, created, percentage, ...newItem } = item;
      return {
        ...newItem,
        percentage: parseInt(percentage),
      };
    });

    const nextEditData = editedData.map((item) => {
      const { status, created, percentage, ...newItem } = item;
      return {
        ...newItem,
        percentage: parseInt(percentage),
      };
    });

    dispatch(editDiscounts(nextEditData));
    dispatch(createDiscounts(nextCreateData));
    setDiscountsArray([...nextEditData, ...nextCreateData]);
  };

  const handleChange = (id, key, value) => {
    const nextData = [...discountsArray];
    nextData.find((item) => item.id === id)[key] = value;
    setDiscountsArray(nextData);
  };

  const onDeleteClick = () => {
    if (checkedKeys.length > 0) {
      dispatch(deleteDisconts(checkedKeys));
      setCheckedKeys([]);
    }
  };

  const onAddClick = () => {
    const maxOrder = Math.max(...discountsArray.map((item) => item.order), 0);
    const newDiscount = {
      name: '',
      percentage: 0,
      status: 'EDIT',
      created: true,
      isActive: true,
      order: maxOrder + 1,
      internalName: null,
    };

    const nextData = discountsArray.map((item) => ({
      ...item,
      status: 'EDIT',
    }));
    const updatedAddOnsArray = [newDiscount, ...nextData];
    setDiscountsArray(updatedAddOnsArray);
    if (!isEdit) {
      setIsEdit(true);
    }
  };

  const onDuplicateClick = () => {
    const maxOrder = Math.max(...discountsArray.map((item) => item.order), 0);
    const nextData = discountsArray.map((item) => {
      if (checkedKeys.includes(item.id)) {
        return { ...item, isActive: false, status: isEdit ? null : 'EDIT' };
      }
      return item;
    });

    const newDiscounts = checkedKeys.map((id, index) => {
      const original = discountsArray.find((item) => item.id === id);
      const newDiscount = {
        ...original,
        status: 'EDIT',
        created: true,
        isActive: true,
        order: maxOrder + 1 + index,
        internalName: 'new',
      };

      delete newDiscount.id;

      return newDiscount;
    });
    console.log(newDiscounts);

    setDiscountsArray([...newDiscounts, ...nextData]);
    setCheckedKeys([]);
    if (!isEdit) {
      setIsEdit(true);
    }
  };

  const handleDragRow = (sourceId, targetId) => {
    const draggedItem = discountsArray.find((item) => item.id === sourceId);
    const draggedIndex = discountsArray.findIndex(
      (item) => item.id === sourceId
    );
    const targetIndex = discountsArray.findIndex(
      (item) => item.id === targetId
    );

    if (draggedIndex === -1 || targetIndex === -1) {
      return;
    }

    const updatedArray = [...discountsArray];

    updatedArray.splice(draggedIndex, 1);

    updatedArray.splice(targetIndex, 0, draggedItem);

    setDiscountsArray(updatedArray);
  };

  const handleActiveClick = (id, key, value) => {
    const nextData = [...discountsArray];
    const foundItem = nextData.find((item) => item.id === id);
    if (foundItem) {
      const updatedItem = { ...foundItem, [key]: value };
      const index = nextData.findIndex((item) => item.id === id);
      if (index !== -1) {
        nextData[index] = updatedItem;
        setDiscountsArray(nextData);
      }
    }
  };

  useEffect(() => {
    const filteredDiscounts = [...discounts]?.filter(
      (discount) => discount.id !== 17
    );
    const sortedDiscounts = filteredDiscounts.sort((a, b) => b.order - a.order);
    setDiscountsArray(sortedDiscounts);
  }, [discounts]);

  useEffect(() => {
    dispatch(getDiscounts());
  }, []);

  if (loading) {
    return <Loader />;
  }

  const allChecked =
    checkedKeys.length === discountsArray.length && discountsArray.length > 0;
  const isIndeterminate =
    checkedKeys.length > 0 && checkedKeys.length < discountsArray.length;

  return (
    <>
      <Toaster position="top-right" />
      <div className="info-container">
        <div className="info-header">
          <div className="info-header-right">
            <div className="info-header-right-title">Discounts</div>
          </div>
          <div className="info-header-left">
            {isEdit ? (
              <>
                <div className="info-header-left-add" onClick={onAddClick}>
                  <Icon name="plus" styles={{ width: '29', fill: '#26A4ED' }} />
                </div>
                <div className="info-header-left-edit" onClick={onSaveClick}>
                  <Icon name="save" />
                </div>
              </>
            ) : (
              <>
                <div className="info-header-left-add" onClick={onAddClick}>
                  <Icon name="plus" styles={{ width: '29', fill: '#26A4ED' }} />
                </div>
                <div className="info-header-left-edit" onClick={onEditClick}>
                  <Icon name="edit" />
                </div>
                {checkedKeys.length >= 1 && (
                  <div
                    className="info-header-left-edit"
                    onClick={onDuplicateClick}
                  >
                    <Icon name="copy" styles={{ width: '29' }} />
                  </div>
                )}
                {checkedKeys.length <= 1 && (
                  <div
                    className="info-header-left-delete"
                    onClick={onDeleteClick}
                  >
                    <Icon name="delete" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <DndProvider backend={HTML5Backend}>
          <Table
            data={
              isEdit
                ? discountsArray
                : discountsArray.filter((item) => item.isActive)
            }
            autoHeight
          >
            <Column width={50} align="center">
              <HeaderCell
                style={{
                  backgroundColor: '#26A4ED',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                <Checkbox
                  inline
                  checked={allChecked}
                  indeterminate={isIndeterminate}
                  onChange={(value, checked) => handleCheckAll(checked)}
                />
              </HeaderCell>
              <CheckCell
                dataKey="id"
                checkedKeys={checkedKeys}
                onChange={handleCheck}
              />
            </Column>
            <Column
              flexGrow={1}
              align="start"
              verticalalign="middle"
              height={50}
            >
              <HeaderCell
                style={{
                  backgroundColor: '#26A4ED',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                Discounts
              </HeaderCell>
              <EditableCell dataKey="name" onChange={handleChange} />
            </Column>
            {isEdit && (
              <Column
                flexGrow={2}
                align="start"
                verticalalign="middle"
                height={50}
              >
                <HeaderCell
                  style={{
                    backgroundColor: '#26A4ED',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: 16,
                  }}
                >
                  Internal name
                </HeaderCell>
                <EditableCell dataKey="internalName" onChange={handleChange} />
              </Column>
            )}
            <Column flexGrow={3} align="center" verticalalign="middle">
              <HeaderCell
                style={{
                  backgroundColor: '#26A4ED',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                Percentages
              </HeaderCell>
              <EditablePercentageCell
                dataKey="percentage"
                onChange={handleChange}
              />
            </Column>
            {isEdit && (
              <>
                <Column flexGrow={1} align="center" verticalalign="middle">
                  <HeaderCell
                    style={{
                      backgroundColor: '#26A4ED',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    Active
                  </HeaderCell>
                  <EditableVisibleCell
                    dataKey="isActive"
                    onChange={handleActiveClick}
                  />
                </Column>
                <Column flexGrow={1} align="center" verticalalign="middle">
                  <HeaderCell
                    style={{
                      backgroundColor: '#26A4ED',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    Order
                  </HeaderCell>
                  <EditableOrderCell dataKey="order" onDrag={handleDragRow} />
                </Column>
              </>
            )}
          </Table>
        </DndProvider>
      </div>
    </>
  );
};

export default Discounts;
