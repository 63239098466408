import React, { useEffect, useRef, useState } from 'react';
import './Treatments.scss';

import { Checkbox, Table } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../Icon';
import {
  createTreatmentTypes,
  deleteTreatmentTypes,
  deleteTreatmentTypesAddOns,
  editAddOns,
  editTreatmentTypes,
  getAddOns,
  getTreatmentTypes,
  getTreatmentTypesAddOns,
  postTreatmentTypesAddOns,
} from '../../store/reducers/PracticeInfoReducer';
import {
  CheckCell,
  EditableCell,
  EditableFeeCell,
  EditableNumberCell,
  EditableOrderCell,
  EditableRangeCell,
  EditableVisibleCell,
  // RadioCell,
} from '../../utils/cells';
import Loader from '../Loader/Loader';
import { SelectPicker } from 'rsuite';
import toast, { Toaster } from 'react-hot-toast';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const { Column, HeaderCell } = Table;

const Treatments = () => {
  const dispatch = useDispatch();
  const { loading, treatmentTypes, treatmentTypeAddOns } = useSelector(
    (state) => state.treatmentTypes
  );
  const { loading: addOnsLoading, addOns } = useSelector(
    (state) => state.addOns
  );
  const user = JSON.parse(localStorage.getItem('user'));
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [treatmentAddOns, setTreatmentAddOns] = useState([]);
  const [treatment, setTreatment] = useState([]);
  const [addOnsArray, setAddOnsArray] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  let isInitialMount = useRef(true);

  const handleCheck = (value, checked) => {
    const nextCheckedKeys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((key) => key !== value);
    setCheckedKeys(nextCheckedKeys);
  };

  const handleCheckAll = (checked) => {
    const allKeys = checked ? treatments.map((item) => item.id) : [];
    setCheckedKeys(allKeys);
  };

  // let checked = false;
  // let indeterminate = false;

  // if (checkedKeys.length === treatmentTypes?.length) {
  // 	checked = true;
  // } else if (checkedKeys.length === 0) {
  // 	checked = false;
  // } else if (checkedKeys.length > 0 && checkedKeys.length < treatmentTypes?.length) {
  // 	indeterminate = true;
  // }

  // const handleCheckAll = (value, checked) => {
  // 	const keys = checked ? treatmentTypes.map(item => item.id) : [];
  // 	setCheckedKeys(keys);
  // };

  // const handleCheck = (value) => {
  //   // const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
  //   // setCheckedKeys(keys);
  //   const handleDiscard = () => {
  //     isInitialMount.current = true;
  //     setCheckedKeys(value);
  //     if (!isInitialMount.current) {
  //       setTreatmentAddOns(treatmentTypeAddOns);
  //       if (treatmentAddOns.length > 0) {
  //         const newAddOnsArray = addOns.filter((item) => {
  //           const hasName = treatmentAddOns.some(
  //             (item2) => item.name === item2.name
  //           );
  //           return !hasName;
  //         });
  //         setAddOnsArray(newAddOnsArray);
  //       }
  //     }
  //     toast.dismiss();
  //   };

  //   if (checkedKeys === value) {
  //     if (!isInitialMount.current) {
  //       toast((t) => (
  //         <span>
  //           Do you want to save your changes?
  //           <div className="save-toast">
  //             <button
  //               onClick={() => {
  //                 toast.dismiss(t.id);
  //                 handleAddOnsSave();
  //                 setCheckedKeys(null);
  //               }}
  //               className="save-toast-yes"
  //             >
  //               Yes
  //             </button>
  //             <button onClick={() => handleDiscard()} className="save-toast-no">
  //               No
  //             </button>
  //           </div>
  //         </span>
  //       ));
  //     } else {
  //       setCheckedKeys(null);
  //     }
  //   } else {
  //     if (!isInitialMount.current) {
  //       toast((t) => (
  //         <span>
  //           Do you want to save your Add-Ons changes?
  //           <div className="save-toast">
  //             <button
  //               onClick={() => {
  //                 toast.dismiss(t.id);
  //                 handleAddOnsSave();
  //                 setCheckedKeys(value);
  //               }}
  //               className="save-toast-yes"
  //             >
  //               Yes
  //             </button>
  //             <button onClick={() => handleDiscard()} className="save-toast-no">
  //               No
  //             </button>
  //           </div>
  //         </span>
  //       ));
  //     } else {
  //       setCheckedKeys(value);
  //     }
  //   }
  // };

  useEffect(() => {
    console.log('isInitialMount', isInitialMount);
  }, [isInitialMount]);

  const onEditClick = () => {
    setIsEdit(true);
    const nextData = treatments.map((item) => ({
      ...item,
      status: isEdit ? null : 'EDIT',
    }));
    setTreatments(nextData);
  };

  const onDeleteClick = () => {
    if (checkedKeys) {
      dispatch(deleteTreatmentTypes(checkedKeys));
      setCheckedKeys([]);
    }
  };

  const onAddClick = () => {
    const maxOrder = Math.max(...treatments.map((item) => item.order), 0);
    const newTreatment = {
      name: '',
      fee: 0,
      months: 0,
      range: [0, 0],
      insMonths: 0,
      addOnsNumber: 0,
      status: 'EDIT',
      created: true,
      isActive: true,
      order: maxOrder + 1,
      internalName: null,
    };

    const nextData = treatments.map((item) => ({ ...item, status: 'EDIT' }));
    const updatedAddOnsArray = [newTreatment, ...nextData];
    setTreatments(updatedAddOnsArray);
    if (!isEdit) {
      setIsEdit(true);
    }
  };

  const onDuplicateClick = () => {
    const maxOrder = Math.max(...treatments.map((item) => item.order), 0);
    const nextData = treatments.map((item) => {
      if (checkedKeys.includes(item.id)) {
        return { ...item, isActive: false, status: isEdit ? null : 'EDIT' };
      }
      return item;
    });

    const newTreatments = checkedKeys.map((id, index) => {
      const original = treatments.find((item) => item.id === id);
      const newTreatment = {
        ...original,
        addOnsNumber: 0,
        status: 'EDIT',
        created: true,
        isActive: true,
        order: maxOrder + 1 + index,
        internalName: 'new',
      };

      delete newTreatment.id;

      return newTreatment;
    });
    console.log(newTreatments);

    setTreatments([...newTreatments, ...nextData]);
    setCheckedKeys([]);
    if (!isEdit) {
      setIsEdit(true);
    }
  };

  const onCopyClick = (id) => {
    const maxOrder = Math.max(...treatments.map((item) => item.order), 0);
    const selectedTreatment = treatments.find((item) => id === item.id);
    const newTreatment = {
      name: `New ${selectedTreatment.name}`,
      fee: selectedTreatment.fee,
      months: selectedTreatment.months,
      range: selectedTreatment.range,
      insMonths: selectedTreatment.insMonths,
      addOnsNumber: 0,
      status: 'EDIT',
      created: true,
      isActive: true,
      order: maxOrder + 1,
      internalName: null,
    };

    const nextData = treatments.map((item) => ({ ...item, status: 'EDIT' }));
    const updatedAddOnsArray = [newTreatment, ...nextData];
    setTreatments(updatedAddOnsArray);
    if (!isEdit) {
      setIsEdit(true);
    }
  };

  const handleChange = (id, key, value) => {
    const nextData = [...treatments];
    nextData.find((item) => item.id === id)[key] = value;
    setTreatments(nextData);
  };

  const onSaveClick = () => {
    const handleSave = () => {
      setIsEdit(false);
      const sortedData = [...treatments]
        .reverse()
        .map((item, index) => ({ ...item, order: index + 1 }))
        .sort((a, b) => b.order - a.order);

      const createdData = sortedData.filter((item) => item.created);
      const editedData = sortedData.filter((item) => !item?.created);

      const nextCreateData = createdData.map((item) => {
        const {
          status,
          created,
          addOnsNumber,
          fee,
          insMonths,
          months,
          range,
          ...newItem
        } = item;
        const newRange = range.map((value) => parseInt(value));
        return {
          ...newItem,
          addOnsNumber: parseInt(addOnsNumber),
          fee: +fee,
          insMonths: parseInt(insMonths),
          months: parseInt(months),
          range: newRange,
        };
      });

      const nextEditData = editedData.map((item) => {
        const {
          status,
          created,
          addOnsNumber,
          fee,
          insMonths,
          months,
          range,
          ...newItem
        } = item;
        const newRange = range.map((value) => parseInt(value));
        return {
          ...newItem,
          addOnsNumber: parseInt(addOnsNumber),
          fee: +fee,
          insMonths: parseInt(insMonths),
          months: parseInt(months),
          range: newRange,
        };
      });

      dispatch(editTreatmentTypes(nextEditData));
      dispatch(createTreatmentTypes(nextCreateData));
    };

    if (!isInitialMount.current) {
      handleAddOnsSave();
      handleSave();
    } else {
      handleSave();
    }

    // const handleDiscard = id => {
    // 	const sortedTreatment = [...treatmentTypes]?.sort((a, b) => b.order - a.order);
    // 	setTreatments(sortedTreatment);
    // 	if (!isInitialMount.current) {
    // 		// toast.dismiss(id);
    // 		setTreatmentAddOns(treatmentTypeAddOns);
    // 		if (treatmentAddOns.length > 0) {
    // 			const newAddOnsArray = addOns.filter(item => {
    // 				const hasName = treatmentAddOns.some(item2 => item.name === item2.name);
    // 				return !hasName;
    // 			});
    // 			setAddOnsArray(newAddOnsArray);
    // 		}
    // 	}
    // 	// setIsEdit(false);
    // 	toast.dismiss();
    // };

    // toast(t => (
    // 	<span>
    // 		<b>Do you want to save your changes?</b>
    // 		<div className="save-toast">
    // 			<button
    // 				onClick={() => {
    // 					if (!isInitialMount.current) {
    // 						toast.dismiss(t.id);
    // 						handleAddOnsSave();
    // 						handleSave();
    // 					}
    // 					toast.dismiss(t.id);
    // 					handleSave();
    // 				}}
    // 				className="save-toast-yes">
    // 				Yes
    // 			</button>
    // 			<button onClick={() => handleDiscard()} className="save-toast-no">
    // 				No
    // 			</button>
    // 		</div>
    // 	</span>
    // ));
  };

  const onSelectAddOn = (e) => {
    const addOn = addOns.filter((item) => item.id === +e.target.value);
    isInitialMount.current = false;

    treatmentAddOns.length > 0
      ? setTreatmentAddOns((prev) => [
          ...prev,
          { treatmentTypeId: treatment[0].id, addOnId: addOn[0].id },
        ])
      : setTreatmentAddOns([
          { treatmentTypeId: treatment[0].id, addOnId: addOn[0].id },
        ]);
  };

  const handleAddOnDelete = (id) => {
    console.log(id);
    dispatch(
      deleteTreatmentTypesAddOns({
        treatmentTypeId: treatment[0].id,
        addOnId: id,
      })
    ).then(() => {
      dispatch(getTreatmentTypesAddOns({ ids: [checkedKeys] }));
    });
  };

  const handleDragRow = (sourceId, targetId) => {
    const draggedItem = treatments.find((item) => item.id === sourceId);
    const draggedIndex = treatments.findIndex((item) => item.id === sourceId);
    const targetIndex = treatments.findIndex((item) => item.id === targetId);

    console.log('draggedItem', draggedItem);
    console.log('draggedIndex', draggedIndex);
    console.log('targetIndex', targetIndex);

    if (draggedIndex === -1 || targetIndex === -1) {
      return;
    }

    const updatedArray = [...treatments];

    updatedArray.splice(draggedIndex, 1);

    updatedArray.splice(targetIndex, 0, draggedItem);

    setTreatments(updatedArray);
  };

  const handleActiveClick = (id, key, value) => {
    const nextData = [...treatments];
    const foundItem = nextData.find((item) => item.id === id);
    if (foundItem) {
      const updatedItem = { ...foundItem, [key]: value };
      const index = nextData.findIndex((item) => item.id === id);
      if (index !== -1) {
        nextData[index] = updatedItem;
        setTreatments(nextData);
      }
    }
  };

  const handleAddOnsSave = () => {
    const extractedAddOnIds = selectedAddOns.map((item) => item.id) || [];
    isInitialMount.current = true;
    dispatch(
      postTreatmentTypesAddOns({
        treatmentTypeId: treatment[0].id,
        addOnsIds: extractedAddOnIds,
      })
    );
  };

  useEffect(() => {
    const sortedTreatment = [...treatmentTypes]?.sort(
      (a, b) => b.order - a.order
    );
    setTreatments(sortedTreatment);
  }, [treatmentTypes]);

  useEffect(() => {
    setTreatmentAddOns(treatmentTypeAddOns);
  }, [treatmentTypeAddOns]);

  useEffect(() => {
    if (checkedKeys) {
      const filteredAddOns = addOns.filter(
        (addOn) =>
          !selectedAddOns.some((selectedAddOn) => selectedAddOn.id === addOn.id)
      );
      setAddOnsArray(filteredAddOns);
    }
  }, [addOns, treatment, selectedAddOns]);

  useEffect(() => {
    console.log(addOnsArray);
  }, [addOnsArray]);

  useEffect(() => {
    if (checkedKeys.length === 0) {
      setTreatment([]);
      setTreatmentAddOns([]);
      setSelectedAddOns([]);
    }
  }, [checkedKeys]);

  useEffect(() => {
    if (treatmentAddOns.length > 0) {
      const newAddOnsArray = addOns.filter((item) => {
        const hasName = treatmentAddOns.some(
          (item2) => item.name === item2.name
        );
        return !hasName;
      });
      setAddOnsArray(newAddOnsArray);
    }
  }, [treatmentAddOns, treatmentTypeAddOns]);

  useEffect(() => {
    dispatch(getTreatmentTypes({ id: user.practiceInfoId }));
    dispatch(getAddOns());
  }, []);

  useEffect(() => {
    if (checkedKeys.length === 1) {
      const singleTreatment = treatments.filter(
        (item) => item.id === checkedKeys[0]
      );
      setTreatment(singleTreatment);
      if (
        (singleTreatment[0].hasOwnProperty('created') &&
          singleTreatment[0]?.created === false) ||
        singleTreatment[0].hasOwnProperty('created') === false
      ) {
        dispatch(getTreatmentTypesAddOns({ ids: [checkedKeys] }));
      }
    }
  }, [checkedKeys]);

  useEffect(() => {
    const filteredAddOns = addOns.filter((addOn) => {
      if (treatmentAddOns.length > 0) {
        return treatmentAddOns.some(
          (treatmentAddOn) => treatmentAddOn.addOnId === addOn.id
        );
      } else {
        return false;
      }
    });

    // setSelectedAddOns(filteredAddOns.length > 1 ? filteredAddOns?.sort((a, b) => b.order - a.order) : filteredAddOns);
    setSelectedAddOns(filteredAddOns);
  }, [treatmentAddOns]);

  useEffect(() => {
    console.log('treatments', treatments);
  }, [treatments]);

  if (loading) {
    return <Loader />;
  }

  const allChecked =
    checkedKeys.length === treatments.length && treatments.length > 0;
  const isIndeterminate =
    checkedKeys.length > 0 && checkedKeys.length < treatments.length;

  const Selector = ({ onSelectAddOn }) => {
    return (
      <select value="" onChange={onSelectAddOn}>
        <option value="" disabled hidden>
          Select Add-Ons...
        </option>
        {addOnsArray.length > 0 ? (
          [...addOnsArray]
            .filter((addon) => addon.isActive)
            ?.sort((a, b) => b.order - a.order)
            ?.map((item) => (
              <option key={item.id} value={item.id} className="option">
                <p className="option-name">{`${item.internalName ? item.internalName : ''} ${item.name}  `}</p>
                <br />
                <p className="option-fee">${item.fee}</p>
              </option>
            ))
        ) : (
          <option value="null" key="none" disabled>
            No available Add-Ons
          </option>
        )}
      </select>
    );
  };

  return (
    <>
      <Toaster position="top-right" />
      <div className="info-container">
        <div className="info-header">
          <div className="info-header-right">
            <div className="info-header-right-title">Treatment types</div>
          </div>
          <div className="info-header-left">
            {isEdit ? (
              <>
                <div className="info-header-left-add" onClick={onAddClick}>
                  <Icon name="plus" styles={{ width: '29', fill: '#26A4ED' }} />
                </div>
                <div className="info-header-left-edit" onClick={onSaveClick}>
                  <Icon name="save" />
                </div>
              </>
            ) : (
              <>
                <div className="info-header-left-add" onClick={onAddClick}>
                  <Icon name="plus" styles={{ width: '29', fill: '#26A4ED' }} />
                </div>
                <div className="info-header-left-edit" onClick={onEditClick}>
                  <Icon name="edit" />
                </div>
                {checkedKeys.length >= 1 && (
                  <div
                    className="info-header-left-edit"
                    onClick={onDuplicateClick}
                  >
                    <Icon name="copy" styles={{ width: '29' }} />
                  </div>
                )}
                {checkedKeys.length <= 1 && (
                  <div
                    className="info-header-left-delete"
                    onClick={onDeleteClick}
                  >
                    <Icon name="delete" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <DndProvider backend={HTML5Backend}>
          <Table
            data={
              isEdit ? treatments : treatments.filter((item) => item.isActive)
            }
            autoHeight
          >
            <Column width={50} align="center">
              <HeaderCell
                style={{
                  backgroundColor: '#26A4ED',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                <div>
                  <Checkbox
                    inline
                    checked={allChecked}
                    indeterminate={isIndeterminate}
                    onChange={(value, checked) => handleCheckAll(checked)}
                  />
                </div>
              </HeaderCell>
              <CheckCell
                dataKey="id"
                checkedKeys={checkedKeys}
                onChange={handleCheck}
              />
              {/* <RadioCell
                dataKey="id"
                checkedKeys={checkedKeys}
                onChange={handleCheck}
              /> */}
            </Column>
            <Column
              flexGrow={2}
              align="start"
              verticalalign="middle"
              height={50}
            >
              <HeaderCell
                style={{
                  backgroundColor: '#26A4ED',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                Treatment Type
              </HeaderCell>
              <EditableCell dataKey="name" onChange={handleChange} />
            </Column>
            {isEdit && (
              <Column
                flexGrow={2}
                align="start"
                verticalalign="middle"
                height={50}
              >
                <HeaderCell
                  style={{
                    backgroundColor: '#26A4ED',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: 16,
                  }}
                >
                  Internal name
                </HeaderCell>
                <EditableCell dataKey="internalName" onChange={handleChange} />
              </Column>
            )}
            <Column flexGrow={2} align="center" verticalalign="middle">
              <HeaderCell
                style={{
                  backgroundColor: '#26A4ED',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                Fee
              </HeaderCell>
              <EditableFeeCell dataKey="fee" onChange={handleChange} />
            </Column>
            <Column flexGrow={2} align="center" verticalalign="middle">
              <HeaderCell
                style={{
                  backgroundColor: '#26A4ED',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                BP Mos
              </HeaderCell>
              <EditableNumberCell dataKey="months" onChange={handleChange} />
            </Column>
            <Column flexGrow={2} align="center" verticalalign="middle">
              <HeaderCell
                style={{
                  backgroundColor: '#26A4ED',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                Range
              </HeaderCell>
              <EditableRangeCell dataKey="range" onChange={handleChange} />
            </Column>
            <Column flexGrow={2} align="center" verticalalign="middle">
              <HeaderCell
                style={{
                  backgroundColor: '#26A4ED',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: 16,
                }}
              >
                INS Mos
              </HeaderCell>
              <EditableNumberCell dataKey="insMonths" onChange={handleChange} />
            </Column>
            {isEdit && (
              <>
                <Column flexGrow={1} align="center" verticalalign="middle">
                  <HeaderCell
                    style={{
                      backgroundColor: '#26A4ED',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    Active
                  </HeaderCell>
                  <EditableVisibleCell
                    dataKey="isActive"
                    onChange={handleActiveClick}
                  />
                </Column>
                <Column flexGrow={1} align="center" verticalalign="middle">
                  <HeaderCell
                    style={{
                      backgroundColor: '#26A4ED',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    Order
                  </HeaderCell>
                  <EditableOrderCell dataKey="order" onDrag={handleDragRow} />
                </Column>
              </>
            )}
          </Table>
        </DndProvider>
        <div className="treatment-addons-container">
          {addOnsLoading ? (
            <Loader />
          ) : (
            <>
              {checkedKeys.length === 1 && !treatment[0]?.created && (
                <div className="treatment-addons">
                  <div className="treatment-addons-header">
                    <h4 className="treatment-addons-title">
                      {treatment[0]?.name} - Add-Ons
                    </h4>
                    {isEdit && (
                      <div
                        className="treatment-addons-title-edit"
                        onClick={handleAddOnsSave}
                      >
                        <Icon name="save" />
                      </div>
                    )}
                  </div>
                  {isEdit && <Selector onSelectAddOn={onSelectAddOn} />}

                  {selectedAddOns.length > 0 ? (
                    <div className="treatment-addons-wrapper">
                      {[...selectedAddOns]
                        .sort((a, b) => b.order - a.order)
                        .map((item) => {
                          return (
                            <div
                              className="treatment-addon-wrapper"
                              key={item.id}
                            >
                              <div className="treatment-addon-wrapper-details">
                                <div className="treatment-addon-details-title">
                                  {item.name}
                                </div>
                                <div className="treatment-addon-details-fee">
                                  ${item.fee}.00
                                </div>
                              </div>
                              {isEdit && (
                                <button
                                  className="treatment-addon-delete"
                                  onClick={() => handleAddOnDelete(item.id)}
                                >
                                  <Icon name="delete" />
                                </button>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <p>No Add-Ons for this Treatment Type</p>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Treatments;
