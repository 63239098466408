import { format } from 'date-fns';

export const cardsList = [
  { label: 'Visa', value: 'Visa' },
  { label: 'MasterCard', value: 'Master Card' },
  { label: 'AMEX', value: 'American Express' },
  { label: 'Discover', value: 'Discover' },
];

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
};

export const formatMiniPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
};

export function cumipmt(rate, periods, value, start, end, type) {
  if (rate <= 0 || periods <= 0 || value <= 0) {
    return 0;
  }

  if (start < 1 || end < 1 || start > end) {
    return 0;
  }

  if (type !== 0 && type !== 1) {
    return 0;
  }

  const payment = PMT(rate, periods, value, 0, type);
  let interest = 0;

  if (start === 1) {
    if (type === 0) {
      interest = -value;
      start++;
    }
  }

  for (let i = start; i <= end; i++) {
    if (type === 1) {
      interest += FV(rate, i - 2, payment, value, 1) - payment;
    } else {
      interest += FV(rate, i - 1, payment, value, 0);
    }
  }

  interest *= rate;

  return interest;
}

function FV(rate, periods, payment, value, type) {
  value = value || 0;
  type = type || 0;

  let result;
  if (rate === 0) {
    result = value + payment * periods;
  } else {
    let term = Math.pow(1 + rate, periods);
    if (type === 1) {
      result = value * term + (payment * (1 + rate) * (term - 1)) / rate;
    } else {
      result = value * term + (payment * (term - 1)) / rate;
    }
  }
  return -result;
}

function PMT(rate, periods, present, future, type) {
  future = future || 0;
  type = type || 0;

  let result;
  if (rate === 0) {
    result = (present + future) / periods;
  } else {
    let term = Math.pow(1 + rate, periods);
    if (type === 1) {
      result =
        ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) /
        (1 + rate);
    } else {
      result = (future * rate) / (term - 1) + (present * rate) / (1 - 1 / term);
    }
  }
  return -result;
}

export const stringToPdf = (contract) => {
  const byteCharacters = atob(contract.pdf);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'application/pdf' });

  return blob;
};

export const calculateWithdrawalDate = (bandingDate) => {
	const MINIMUM_DAYS = 30;
	const THRESHOLDS = [5, 12, 19, 26];
  
	const bandingDateObj = new Date(bandingDate);
  
	// const adjustedDate = new Date(bandingDateObj.getTime());
	// adjustedDate.setDate(adjustedDate.getDate() + MINIMUM_DAYS);
  
	const nextMonth = new Date(bandingDateObj.getFullYear(), bandingDateObj.getMonth() + 1, 1);
  
	for (let threshold of THRESHOLDS) {
	  const candidateDate = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), threshold);
	  if (candidateDate > bandingDateObj) {
		return candidateDate;
	  }
	}
  
	return new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 5);
  };
  

export const getMonth = (date) => {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];
    return months[date.getMonth()];
  };
export const getDay = (date) => format(new Date(date), 'dd');
export const getYear = (date) => format(new Date(date), 'yyyy');

export const getCardType = (cardNumber) => {
  const cardPatterns = {
    visa: /^4[0-9]{12}(?:[0-9]{3})?(?:[0-9]{3})?$/,
    mastercard: /^(5[1-5][0-9]{14}|2(?:2[2-9][0-9]{12}|[3-7][0-9]{13}))$/,
    amex: /^3[47][0-9]{13}$/,
    discover: /^6(?:011|5[0-9]{2})(?:[0-9]{12})$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    maestro: /^(?:5018|5020|5038|6304|6759|6761|6763)\d{8,15}$/,
  };

  for (const [cardType, pattern] of Object.entries(cardPatterns)) {
    if (pattern.test(cardNumber)) {
      return cardType.charAt(0).toUpperCase() + cardType.slice(1);
    }
  }

  return 'Unknown';
};

export const getTodayDate = () => {
  return format(new Date(), 'MM/dd/yyyy');
};

export const cutString = (str) => {
  const pipeIndex = str.indexOf('|');

  if (pipeIndex !== -1) {
    return str.substring(0, pipeIndex).trim();
  }

  return str.trim();
};

export const cutSymbol = (str) => {
  return str.replace(/\|/g, '').trim();
};
