import React, { useEffect, useState } from 'react';
import './PresentationSidebar.scss';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../Icon';
import { getPresent } from '../../store/reducers/PresentationReducer';
import Loader from '../Loader/Loader';
import { getConsultsById } from '../../store/reducers/ConsultReducer';

const presentCards = [
  {
    id: 1,
    name: 'Complex Phase 1',
    price: '4,630',
  },
  {
    id: 2,
    name: 'Regular Phase 1',
    price: '4,190',
  },
];

const PresentationSidebar = () => {
  const dispatch = useDispatch();
  const { presentationTreatments, loading } = useSelector(
    (state) => state.presentation
  );
  const [availTreatments, setAvailTreatments] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { id, date } = useParams();

  useEffect(() => {
    dispatch(getPresent({ id }));
    dispatch(getConsultsById({ id }));
  }, [id]);

  useEffect(() => {
    setAvailTreatments(presentationTreatments);
  }, [presentationTreatments]);

  useEffect(() => {
    console.log(presentationTreatments);
  }, [presentationTreatments]);

  if (
    loading ||
    presentationTreatments.length === 0 ||
    availTreatments.length === 0
  ) {
    return (
      <div className="present-sidebar">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={isOpen ? 'present-sidebar-open' : 'present-sidebar'}>
        <div className="present-sidebar-info-title">
          AVAILABLE TREATMENT TYPES
        </div>
        <div className="present-sidebar-info-cards">
          {[...presentationTreatments]
            .sort((a, b) => b.order - a.order)
            .map((card) => (
              <NavLink
                key={card.id}
                to={`/present/${id}/${date}/${card?.id}`}
                className={({ isActive, isPending }) => 'present-card'}
				onClick={() => setIsOpen(false)}
              >
                {({ isActive, isPending, isTransitioning }) => (
                  <>
                    <div className="present-sidebar-info-card-info">
                      <p>{card.name}</p>
                      <p>{`$${card.fee.toFixed(2)}`}</p>
                    </div>
                    <Icon
                      name={`${isActive ? 'arrow-right' : 'arrow-left'}`}
                      styles={{
                        fill: `${card?.isEdit ? '#F15757' : '#26A4ED'}`,
                      }}
                    />
                  </>
                )}
              </NavLink>
            ))}
        </div>
      </div>
      <button className={isOpen ? "open-sidebar": ''} onClick={() => setIsOpen(!isOpen)}>
        <svg
          width="24"
          height="24"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu"
        >
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
      </button>
    </>
  );
};

export default PresentationSidebar;
