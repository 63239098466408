import React, { useEffect, useRef, useState } from 'react';
import './contract.scss';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFRenderer,
  PDFViewer,
  pdf,
} from '@react-pdf/renderer';
import { useDispatch, useSelector } from 'react-redux';
// import { Loader } from 'rsuite';
import {
  getPresentById,
  signContract,
} from '../../store/reducers/PresentationReducer';
import { useNavigate, useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import Popup from 'reactjs-popup';
import { cutString, cutSymbol, formatMiniPhoneNumber } from '../../utils/utils';
import Loader from '../Loader/Loader';
import Icon from '../../Icon';
import toast, { Toaster } from 'react-hot-toast';
import ContractDownload from './ContractDownload';
import { downloadStyles } from './styles';
import { saveAs } from 'file-saver';
import { unwrapResult } from '@reduxjs/toolkit';

const ContractDocument = ({ isDownload, styles }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { presentation, contract, loading } = useSelector(
    (state) => state.presentation
  );
  const [presentationInfo, setPresentationInfo] = useState(null);
  const { id, date, treatmentId, payment } = useParams();
  const [signature, setSignature] = useState(null);
  const sigCanvas = useRef({});

  useEffect(() => {
    console.log(sigCanvas);
  }, [sigCanvas]);

  const handleSave = async () => {
    const signatureURL = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL('image/png');
    const blob = await pdf(
      <ContractDownload
        isDownload={true}
        styles={downloadStyles}
        presentationInfo={presentationInfo}
        signature={signatureURL}
      />
    ).toBlob();
    console.log(blob);
    // saveAs(blob, 'document.pdf');
    dispatch(
      signContract({
        file: blob,
        id: id,
        sum:
          presentationInfo.treatment.name === 'TennCare Full'
            ? 0
            : presentationInfo.calculations.payments.bpContract_calc,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        toast.dismiss();
        // dispatch(getPresentById({ id, date, treatmentId })).then(() => {
        // 	setSignature(signatureURL);
        // });
        console.log(result);

        if (
          result?.status === 5 ||
          presentationInfo?.calculations?.payments
            ?.bpSelectedDownpayment_calc === 0 ||
          presentationInfo?.discount?.name === 'No Charge'
        ) {
          navigate(`/patient-profile/${id}/${date}/${treatmentId}`);
        } else {
          navigate(`/payment/${id}/${date}/${treatmentId}`);
        }
      });
  };

  useEffect(() => {
    if (!presentationInfo?.contractUrl) {
      showContractPopup();
    }
  }, [presentationInfo]);

  const showContractPopup = () => {
    toast(
      (t) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ textAlign: 'center', fontWeight: '500', marginTop: '0' }}>
            Please scroll down to accept these terms and sign the contract!
          </p>
          <button
            onClick={scrollToContract}
            style={{
              color: 'white',
              backgroundColor: '#800000',
              padding: '5px 10px',
              borderRadius: '8px',
            }}
          >
            Sign contract
          </button>
        </div>
      ),
      {
        duration: Infinity,
        id: 'contract-popup',
      }
    );
  };

  const scrollToContract = () => {
    const contractElement = document.getElementById('sign');
    if (contractElement) {
      contractElement.scrollIntoView({ behavior: 'smooth' });
    }
    toast.dismiss();
  };

  const clear = () => sigCanvas.current.clear();

  useEffect(() => {
    setPresentationInfo(presentation);
  }, [presentation]);

  useEffect(() => {
    if (!presentation) {
      dispatch(getPresentById({ id, date, treatmentId }));
    }
  }, []);

  if (loading || !presentationInfo) {
    return <Loader />;
  }

  const formatVersion = (date) => {
    const version = new Date(date);
    const day = String(version.getDate()).padStart(2, '0');
    const month = String(version.getMonth() + 1).padStart(2, '0');
    const year = version.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const formatDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <>
      <Toaster position="top-right" />
      <Document
        creator={presentationInfo?.organization?.name}
        producer={presentationInfo?.organization?.name}
        style={styles.body}
      >
        <Page size="A4" style={styles.page}>
          <View style={styles.section_header}>
            {isDownload ? (
              <Image
                src={presentationInfo?.organization?.logo}
                style={styles.logo}
                alt="logo"
                width={80}
                height={80}
              />
            ) : (
              <img
                src={presentationInfo?.organization?.logo}
                style={styles.logo}
                alt="logo"
              />
            )}

            <Text style={styles.header}>
              Federal Truth in Lending Disclosure Statement
              <br /> Payment Plan for Professional Services
              <br />
              {cutSymbol(
                presentationInfo.calculations.payments.treatmentType_calc
              )}
            </Text>
          </View>
          <View style={styles.section_patient_info}>
            <View
              style={
                isDownload
                  ? [
                      styles.section_patient_info_element,
                      { marginRight: '10px' },
                    ]
                  : styles.section_patient_info_element
              }
            >
              <Text style={styles.patient_text}>Patient Name</Text>
              <Text style={styles.patient_text_underlined}>
                {presentationInfo.firstName} {presentationInfo.lastName}
              </Text>
            </View>
            <View style={styles.section_patient_info_element}>
              <Text style={styles.patient_text}>Patient Number</Text>
              <Text style={styles.patient_text_underlined}>
                {presentationInfo.patientNumber}
              </Text>
            </View>
          </View>
          <View style={styles.section_patient_details}>
            <View style={styles.section_patient_details_element}>
              <Text style={styles.patient_details}>Net Patient Investment</Text>
              <Text style={styles.patient_details_underlined}>
                $
                {presentationInfo.calculations.payments.bpContract_calc.toFixed(
                  2
                )}
              </Text>
            </View>
            <View style={styles.section_patient_details_element}>
              <Text style={styles.patient_details}>Less Down Payment</Text>
              <Text style={styles.patient_details_underlined}>
                -$
                {presentationInfo.calculations.payments.bpSelectedDownpayment_calc.toFixed(
                  2
                )}
              </Text>
            </View>
            <View style={styles.section_patient_details_element}>
              <Text style={styles.patient_details}>
                Less Down Payment Discount
              </Text>
              <Text style={styles.patient_details_underlined}>
                $
                {presentationInfo.calculations.payments.bpDownPaymentDiscount_calc.toFixed(
                  2
                )}
              </Text>
            </View>
            <View style={styles.section_patient_details_element}>
              <Text style={styles.patient_details}>
                Deferred Payment Balance
              </Text>
              <Text style={styles.patient_details_underlined}>
                $
                {presentationInfo.calculations.payments.bpDefferedBalance_calc.toFixed(
                  2
                )}
              </Text>
            </View>
            <View style={styles.section_patient_details_element}>
              <Text style={styles.patient_details}>Plus Finance Charge</Text>
              <Text style={styles.patient_details_underlined}>
                $
                {presentationInfo.calculations.payments.bpInterest_calc.toFixed(
                  2
                )}
              </Text>
            </View>
            <View style={styles.section_patient_details_element}>
              <Text style={styles.patient_details}>Total Unpaid Balance</Text>
              <Text style={styles.patient_details_underlined}>
                $
                {(
                  presentationInfo.calculations.payments
                    .bpDefferedBalance_calc +
                  presentationInfo.calculations.payments.bpInterest_calc
                ).toFixed(2)}
              </Text>
            </View>
          </View>
          <View style={styles.section_patient_text}>
            <Text style={styles.section_patient_text_details}>
              {
                presentationInfo.calculations.contract
                  .bpContractDescription_calc
              }
              <br />
              <br />
            </Text>
            <Text style={styles.section_patient_text_details}>
              {presentationInfo.calculations.contract.bpDeclinedDiscount_calc}
              <br />
              <br />
            </Text>
            <Text style={styles.section_patient_text_details}>
              {presentationInfo.calculations.notes.notesInsurance_calc}
              {/* Please let us know if the patient becomes eligible for orthodontic insurance coverage so we
							can help you collect your benefit. */}
              <br />
              <br />
            </Text>
            {/* <Text style={styles.section_patient_text_details}>
						This contract covers all appliances and treatment visits up to, but not including Phase II
						treatment.
						<br />
						<br />
					</Text> */}
            <Text style={styles.section_patient_text_details}>
              This fee covers the prescribed orthodontic appliances and
              treatment visits. It does not cover lost or broken appliances or
              work done by other dentists.
              <br />
              <br />
            </Text>
            <Text style={styles.section_patient_text_details}>
              I HEREBY CERTIFY, that I have read and received a copy of the
              foregoing disclosure statement on this and agree to the terms
              thereof.
              <br />
              <br />
            </Text>
          </View>
          <View style={styles.section_patient_signature} id="sign">
            <View style={styles.section_patient_info_element}>
              <Text style={styles.patient_text}>Signature:</Text>
              <Text style={styles.patient_text_underlined}>
                {/* <div> */}
                {signature ? (
                  <>
                    <img
                      src={signature}
                      alt="Signature"
                      height={60}
                      width={187.5}
                      className="signature-img"
                    />
                  </>
                ) : (
                  <Popup
                    trigger={
                      <button className="signature-button">
                        Add Signature
                      </button>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <h2 className="modal-header">Sign here</h2>
                        <SignatureCanvas
                          ref={sigCanvas}
                          penColor="black"
                          canvasProps={{
                            width: 375,
                            height: 120,
                            className: 'sigCanvas',
                          }}
                        />
                        <div className="buttons-block">
                          <button onClick={handleSave} className="save-button">
                            Save
                          </button>
                          <button onClick={clear} className="clear-button">
                            Clear
                          </button>
                          <button onClick={close} className="close-button">
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </Popup>
                )}
                {/* </div> */}
              </Text>
            </View>
            <View style={styles.section_patient_info_element}>
              <Text style={styles.patient_text}>Date:</Text>
              <Text style={styles.patient_text_underlined}>{formatDate()}</Text>
            </View>
          </View>
          <View style={styles.section_dolphin}>
            <View style={styles.section_dolphin_title}>
              <Text style={styles.section_dolphin_title_text}>
                Office Use Only
              </Text>
              <Text style={styles.section_dolphin_title_text}>
                Version{' '}
                {formatVersion(presentationInfo?.organization?.lastUpdated)}
              </Text>
            </View>
            <View style={styles.section_dolphin_details}>
              <View style={styles.section_dolphin_details_element}>
                <Text style={styles.section_dolphin_details_element_title}>
                  Dolphin Fees Tab
                </Text>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Treatment
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {cutString(
                      presentationInfo.calculations.payments.treatmentType_calc
                    )}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Gross Tx Fee
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.grossTxFee_d_calc.toFixed(
                      2
                    )}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Courtesy Disc
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.courtesyDisc_d_calc}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Courtesy Amt
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.courtesyAmt_d_calc.toFixed(
                      2
                    )}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    DownPmt Disc
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.downPmtDisc_d_calc.toFixed(
                      2
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.section_dolphin_details_element}>
                <Text style={styles.section_dolphin_details_element_title}>
                  Dolphin Pt Financials - INS
                </Text>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Next Form
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {formatVersion(
                      presentationInfo.calculations.dolphin.nextForm_d_calc
                    )}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Start Benefit
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.startBenefit_d_calc}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Tx Amount
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.txAmount_d_calc.toFixed(
                      2
                    )}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Expected %
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.expected_d_calc}%
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Ins Payout
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.insPayout_d_calc}
                  </Text>
                </View>
              </View>
              <View style={styles.section_dolphin_details_element}>
                <Text style={styles.section_dolphin_details_element_title}>
                  Dolphin Pt Financials - BP
                </Text>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Charge Day
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.chargeDay_d_calc}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Contract Amt
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.contractAmt_d_calc.toFixed(
                      2
                    )}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Down Pmt
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.downPmt_d_calc}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    Monthly Chg
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {presentationInfo.calculations.dolphin.monthlyChg_d_calc}
                  </Text>
                </View>
                <View style={styles.section_dolphin_details_element_row}>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    # of Changes
                  </Text>
                  <Text style={styles.section_dolphin_details_element_row_text}>
                    {
                      presentationInfo.calculations.dolphin
                        .numberOfCharges_d_calc
                    }
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={styles.section_footer_title}>
            <Text style={styles.section_footer_title_text}>
              FMY Orthodontics
            </Text>
          </View>
          <View style={styles.section_footer_title}>
            <Text style={styles.section_footer_title_text}>
              {/* Jackson &bull; Martin &bull; Dyersburg &bull; Henderson &bull; Paris */}
              {presentationInfo?.organization?.tagLine}
            </Text>
          </View>
          <View style={styles.section_footer_title}>
            <Text style={styles.section_footer_title_text}>
              {presentationInfo?.organization?.phones.map((phone) => {
                return ` ${formatMiniPhoneNumber(phone)} |`;
              })}{' '}
              www.fmyortho.com
            </Text>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default ContractDocument;
